.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  height: 100vh;
  margin: auto;
  background: pink;
}

.controls-container {
  position: absolute;
  display: flex;
  /* background: rgba(255,255,255,.4); */
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0px;
  padding: 10px;
}

.slider-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  /* border: 1px black solid; */
  flex-flow: column nowrap;
  position: absolute;
}

.slider {
  display: block;
  -webkit-appearance: none;
  appearance: none;
  width: 400px; 
  height: 3px;
  margin: auto;
  /* background: rgba(255,255,255,.7); */
  background: black;
  border-radius: 3px;
  /* border-top: 3px black solid;
  border-bottom: 3px black solid; */
  /* box-sizing: border-box;
  flex: none; */
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.dateTime {
  position: absolute;
  bottom: 20px;
  /* bottom: 40px; */
  /* bottom: 8px; */
  left: calc(50% + 20px);
  transform: translate(-50%, -50%);
  margin: none;
  padding: 5px;
  /* background: rgba(255,255,255,.7); */
  background: rgba(0,0,0,.8);
  color: white;
  border-radius: 5px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 40px;
  background: black;
  cursor: pointer;
  border-radius: 5px;
}

.slider-labels {
  display: flex;
  flex-direction: row;
  width: 395px;
  height: 10px;
  pointer-events: none;
  position: absolute;
  bottom: 93px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.slider-tick {
  width: 100%;
  height: 100%;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.animateBtn {
  position: absolute;
  bottom: 50px;
  left: calc(50% - 105px);
  background: rgba(0,0,0,.8);
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.slider-bg {
  position: absolute;
  width: 450px;
  height: 60px;
  background: rgba(0,0,0,.8);
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 43px;
  border-radius: 10px;
}

.infoBtn {
  position: absolute;
  width: 40px;
  height: 40px;
  background: rgba(0,0,0,.8);
  color: white;
  /* right: 0px; */
  top: 0px;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
}

.infoBtn2 {
  position: absolute;
  width: 200px;
  height: 30px;
  color: white;
  background: none;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  font-size: 18px;
  outline: none;
  border: none;
  cursor: pointer;
}

.info-container {
  width: 400px;
  height: 100vh;
  background: rgba(20,20,20,.9);
  color: white;
  position: absolute;
  right: 0px;
  padding: 20px;
}

.info-container-2 {
  width: 100%;
  height: 100vh; 
  background: green;
  top: 100vh;
  position: absolute;
}